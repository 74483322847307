import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CustomLayoutComponent} from "./core/main-layout/custom-layout.component";
import {Error404Component} from "./core/errors/error-404/error-404.component";
import {Error500Component} from "./core/errors/error-500/error-500.component";
import { Error403Component } from './core/errors/error403/error403.component';

const routes: Routes = [

  { path: '', redirectTo: '/auth/login', pathMatch: 'full'},
  { path: 'auth', loadChildren: () => import('./features/authentification/authentification.module').then(m => m.AuthentificationModule) },
  { path: 'errors/404', component: Error404Component, data: { containerEnabled: true, toolbarShadowEnabled: true } },
  { path: 'errors/500', component: Error500Component, data: { containerEnabled: true, toolbarShadowEnabled: true}},
  { path: 'errors/403', component: Error403Component, data: { containerEnabled: true, toolbarShadowEnabled: true } },
  {
    path: '',
    component: CustomLayoutComponent,
    // add home feature path to the list after creating it :
    children: [
      { path: 'home', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule) },
      { path: 'customers', loadChildren: () => import('./features/customer/customer.module').then(m => m.CustomerModule) },
      { path: 'turnover', loadChildren: () => import('./features/turnover/turnover.module').then(m => m.TurnoverModule) },
      { path: 'expense', loadChildren: () => import('./features/expense/expense.module').then(m => m.ExpenseModule) },
      { path: 'profile', loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule  ) },
      { path: 'admin', loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule) },
      { path: 'messages', loadChildren: () => import('./features/messages/messages.module').then(m => m.MessagesModule) },
      { path: 'tasks', loadChildren: () => import('./features/tasks/tasks.module').then(m => m.TasksModule) },
      { path: 'contract', loadChildren: () => import('./features/contract/contract.module').then(m => m.ContractModule) },
      {
        path:'activity' , loadChildren: () => import('./features/activity/activity.module').then(m => m.ActivityModule)
      }

    ]
  },
  { path: '**', redirectTo: 'errors/404'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
 //   preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
