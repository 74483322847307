import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessageFacade } from '../../../features/messages/facade/messages.facade';

@Component({
  selector: 'vought-app-header-marge',
  templateUrl: './header-marge.component.html',
  styleUrls: ['./header-marge.component.scss']
})
export class HeaderMargeComponent implements OnInit {
  private paddingSubject = new BehaviorSubject<string>('0px');
  padding$ = this.paddingSubject.asObservable();
  paddingValue = '0px';

  constructor(    private messageFacade: MessageFacade,
  ) { }

  ngOnInit(): void {
    this.setPadding();
    // Subscribe to padding$ to update paddingValue automatically
    this.padding$.subscribe(padding => {
      this.paddingValue = padding;
    });
  }

  setPadding() {
    this.messageFacade.isMessagesEnable().subscribe((isEnable) => {
      const padding = isEnable ? '50px' : '0px';
      this.paddingSubject.next(padding);
    });
  }
}
