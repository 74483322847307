import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthNameUI'
})
export class MonthNameUiPipe implements PipeTransform {
  private readonly months = [
    'Janvier', 'Février', 'Mars', 'Avril',
    'Mai', 'Juin', 'Juillet', 'Août',
    'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  transform(value: number): string {  
    if (value >= 1 && value <= 12) {
      return this.months[value - 1];
    }
    return 'Mois invalide'; 
  }

}
